<template>
  <div style="text-align: center; background-color: white">
    <van-image
        class=""
        :src="require('@/assets/common/payment_complated.png')"
        width="30%"
        height="20%"
    />
  </div>
</template>

<script>
export default {
  name: "androIosPaypalPage",
  data() {
    return {
      count: 0,
      timer:false
    }
  },
  created() {
    this.clickJump();
  },
  methods: {
    //几秒后进入跳转页面
    clickJump(){
      const timejump = 3;
      if(!this.timer){
        this.count = timejump ;
        this.timer = setInterval(()=>{
          if(this.count > 0 && this.count <= timejump ){
            this.count--;
          }else{
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        },1000)
      }
    },
  },
  beforeDestroy() {    //页面关闭时清除定时器
    clearInterval(this.timer);
  },
}
</script>

<style scoped>
#close {
  font-size: 16px;
  text-align: center;
}
</style>
